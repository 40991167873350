// i18next-extract-mark-ns-start documents

import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from 'src/components/layout'
import { Helmet } from 'react-helmet'
import PageTitle from 'src/components/typography/PageTitle'
import Section from 'src/components/typography/Section'
import ContentPageContainer from 'src/components/layout/ContentPageContainer'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Select, { defaultOptionId } from 'src/components/ui/Select'
import { ISelectOption } from 'src/components/ui/SelectOption'
import LawsOfTheGamesDocuments from 'src/components/document/LawsOfTheGameDocuments'
import { IImage } from 'src/components/parallax-image'
import filterBySelectOption, {
  findYearById,
  findLanguageById,
} from 'src/helpers/documents'
import RelatedDocuments from 'src/components/document/RelatedDocuments'
import config from 'src/../tailwind.config'
import { isBrowser } from 'src/helpers/layout'
import { getSeasonFromLocalStorage } from 'src/helpers/seasons'
import lawsStore from 'src/components/laws/laws-store'

const { latestSeason } = require('src/seasons')

export interface IDocument {
  id: string
  name: string
  slug: string
  externalLink: string
  externalLinkUrl: string
  asset: {
    url: string
  }
  year: {
    label: string
    optionId: string
  }
  language: {
    label: string
    optionId: string
  }
  documentType: {
    label: string
    optionId: string
  }
  desktopImage: IImage
  mobileImage: IImage
}

const IndexPage = ({ data }) => {
  const { t } = useTranslation('documents')
  const [selectedYear, setSelectedYear] = useState(null as null | ISelectOption)
  const [selectedLanguage, setSelectedLanguage] = useState(
    null as null | ISelectOption
  )
  const selectedSeason = lawsStore((state) => state.selectedSeason)

  const setUrlParam = (param: string, value: string) => {
    const url = new URL(window.location)
    url.searchParams.set(param, value)
    window.history.pushState({}, '', url)
  }

  const handleYearSelect = (option: ISelectOption) => {
    setSelectedYear(option)

    setUrlParam('year', option.optionId)
  }

  const isListView = () => {
    if (selectedYear && selectedYear.displayDocumentsIcons) {
      return false
    }

    return true
  }

  const handleLanguageSelect = (option: ISelectOption) => {
    setSelectedLanguage(option)

    setUrlParam('language', option.optionId)
  }

  const getFilteredDocuments = () => {
    const filteredLanguage = filterBySelectOption(
      data.documents.items,
      selectedLanguage,
      'language'
    )

    return filterBySelectOption(filteredLanguage, selectedYear, 'years')
  }

  const languageTypesWithDefaultOption = [
    {
      optionId: defaultOptionId,
      label: t('ALL LANGUAGES'),
    },
    ...data.languageTypes.nodes,
  ]

  const setFiltersFromUrl = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    setSelectedYear(findYearById(params.year, data.yearTypes.nodes))
    setSelectedLanguage(
      findLanguageById(params.language, languageTypesWithDefaultOption)
    )
  }

  const getDefaultYear = () => {
    const localStorageSeasonYear =
      getSeasonFromLocalStorage() && getSeasonFromLocalStorage().year

    return localStorageSeasonYear || latestSeason.year
  }

  const autoSelectCurrentYear = () => {
    const defaultYear = data.yearTypes.nodes.find(
      (year) => year.optionId === getDefaultYear()
    )

    if (!defaultYear) {
      return
    }

    handleYearSelect(defaultYear)
  }

  useEffect(() => {
    autoSelectCurrentYear()
    setFiltersFromUrl()
  }, [selectedSeason])

  const getDocumentsView = () => {
    if (isListView()) {
      return (
        <div className="mt-12">
          <RelatedDocuments
            color={config.theme.extend.colors.deepDarkBlue}
            items={getFilteredDocuments()}
          />
        </div>
      )
    }

    return (
      <LawsOfTheGamesDocuments
        documents={getFilteredDocuments() as IDocument[]}
      />
    )
  }

  const isLanguageSetInUrl = (): boolean => {
    if (!isBrowser) {
      return false
    }

    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    return !!params.language
  }

  return (
    <>
      <Helmet>
        <title>{t('Documents')}</title>
      </Helmet>
      <Layout>
        <div className="min-h-screen bg-white text-deepDarkBlue">
          <ContentPageContainer>
            <PageTitle>
              <span>{t('Laws of the game')}</span>
            </PageTitle>
            <Section>
              <div className="mt-12 flex flex-wrap md:flex-nowrap justify-between">
                <Select
                  options={data.yearTypes.nodes}
                  onSelect={handleYearSelect}
                  selectedItem={selectedYear}
                  className="w-full md:w-1/2 md:mr-0.5 mt-4"
                  placeholder={t('ALL YEARS')}
                />
                <Select
                  isSelectedAllAsDefault={!isLanguageSetInUrl()}
                  options={data.languageTypes.nodes}
                  onSelect={handleLanguageSelect}
                  selectedItem={selectedLanguage}
                  className="w-full md:w-1/2 mt-4"
                  placeholder={t('ALL LANGUAGES')}
                />
              </div>
              {getDocumentsView()}
            </Section>
          </ContentPageContainer>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    documentTypes: allDatoCmsDocumentType(
      filter: { locale: { eq: $language } }
      sort: { fields: position }
    ) {
      nodes {
        label
        optionId
      }
    }
    yearTypes: allDatoCmsDocumentYear(
      filter: { locale: { eq: $language }, isOnLawsOfTheGamePage: { eq: true } }
      sort: { fields: position }
    ) {
      nodes {
        label
        optionId
        displayDocumentsIcons
      }
    }
    languageTypes: allDatoCmsDocumentLanguage(
      filter: { locale: { eq: $language } }
      sort: { fields: position }
    ) {
      nodes {
        label
        optionId
      }
    }
    documents: allDatoCmsDocument(
      filter: {
        locale: { eq: $language }
        visibleOnLawsOfTheGameDocumentsPage: { eq: true }
      }
      sort: { order: ASC, fields: position }
    ) {
      items: nodes {
        id
        name
        slug
        externalLink
        externalLinkUrl
        asset {
          url
        }
        years {
          label
          optionId
        }
        language {
          label
          optionId
        }
        desktopImage {
          url
          alt
        }
        mobileImage {
          url
          alt
        }
      }
    }
  }
`

export default IndexPage
