import React, { FC } from 'react'
import SingleLawsOfTheGameDocuments from 'src/components/document/SingleLawsOfTheGameDocument'
import { IDocument } from 'src/pages/laws-of-the-game-documents'

interface ILawsOfTheGameDocumentsProps {
  documents: IDocument[]
}

const LawsOfTheGamesDocuments: FC<ILawsOfTheGameDocumentsProps> = ({
  documents,
}) => {
  const documentComponents = documents.map((document) => (
    <SingleLawsOfTheGameDocuments key={document.id} document={document} />
  ))
  return (
    <div className="flex flex-wrap items-center justify-between mt-8 mb-8">
      {documentComponents}
    </div>
  )
}

export default LawsOfTheGamesDocuments
